<div class="boxWidth">
  <h2 mat-dialog-title>Batch Info</h2>
<mat-list role="list">
  <mat-list-item role="listitem"><b>User Name</b>:&nbsp;{{infoData.user_name}}</mat-list-item>
  <mat-list-item role="listitem"><b>Source</b>:&nbsp;{{infoData.source}}</mat-list-item>
  <mat-list-item role="listitem"><b>Mail to</b>:&nbsp;{{infoData.mail_to}}</mat-list-item>
  <mat-list-item role="listitem"><b>Created At</b>:&nbsp;{{infoData.created_at | date:'short'}}</mat-list-item>
  <mat-list-item role="listitem"><b>Start Date</b>:&nbsp;{{infoData.details.start_date | date:'short'}}</mat-list-item>
  <mat-list-item role="listitem"><b>End Date</b>:&nbsp;{{infoData.details.end_date | date:'short'}}</mat-list-item>
  <mat-list-item role="listitem"><b>User entered ID</b>:&nbsp;{{infoData.details.salesforce_id}}</mat-list-item>
  <mat-list-item role="listitem"><b>Filename</b>:&nbsp;{{infoData.filename}}</mat-list-item>
</mat-list>
</div>

