import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ManualReconService } from './manual-recon.service';
import { behaviour } from 'src/environments/behaviour';
import Swal from 'sweetalert2';
import * as _ from 'lodash';

@Component({
  selector: 'app-manual-recon',
  templateUrl: './manual-recon.component.html',
  styleUrls: ['./manual-recon.component.css']
})
export class ManualReconComponent implements OnInit {
  selectedSource: string = '';
  sub: any;
  selectedId: string = '';
  selectedReconType: string = '';
  recordVal: any;
  selectedVal: any;
  showMatch : boolean = false;
  constructor(private manualReconService: ManualReconService,
    private router: Router,
    private route: ActivatedRoute,private reconService : ManualReconService) { }

  ngOnInit(): void {
    this.sub = this.route.queryParams.subscribe(params => {
      if (params['source']) {
        this.selectedSource = params['source'];
      }

      this.selectedId = params['id'];
      this.selectedReconType = params['recon'];
    });
    this.getRecord();
  }
  getRecord() {
    let batchSingular = behaviour.batchSingular.value[this.selectedSource];
    let directory = behaviour.sourcesConfig[batchSingular]["directory"];
    let selSource = behaviour.sourcesConfig[this.selectedSource].plural

    if (this.selectedReconType === "2") {
      this.showMatch = false;
      let api = `/api/${directory}/${selSource}/recon/${this.selectedId}`;
      this.manualReconService.getSourceData(api).subscribe(data => {
        this.recordVal = data;
      })
    } else if (this.selectedReconType === "3" || this.selectedReconType === "5") {
      this.showMatch = true;
      let api = `/api/${directory}/${selSource}/recon/${this.selectedId}`
      this.manualReconService.getSourceData(api).subscribe(data => {
        this.recordVal = data;
      })
    }

  }
  updateRecords(val){
    let batchSingular = behaviour.batchSingular.value[this.selectedSource];
    let directory = behaviour.sourcesConfig[batchSingular]["directory"];
    let selSource = behaviour.sourcesConfig[this.selectedSource].plural
    let api = '/api/'+directory+'/'+selSource+'/match/'+this.selectedId+'?id='+this.selectedId+'&person_id='+val.id;
    this.reconService.postUpdates(api).subscribe(
      event => {
          this.successNotification();
          this.router.navigateByUrl('/batch-details');
      }
      
  );
  }
  navSales(element){
    let url = 'https://umuc.lightning.force.com/lightning/r/Contact/'+element.salesforce_id+'/view'
    window.open(url, "_blank");
  }
  successNotification(){
    // Swal.fire( this.successData, 'success')
    Swal.fire({
      icon: 'success',
      text: "Matched Successfully",
    })
  }
  reconColor(recon) {
    if (recon.cur_student) {
      return "text_business";
    } else if (recon.current_employee_b) {
      return "text_business";
    } else if (recon.current_alumni_b) {
      return "text_green";
    } else if (recon.former_employee_b) {
      return "text_green";
    } else if (recon.former_student_b) {
      return "text_green";
    } else if (recon.friend_b) {
      return "text_green";
    } else if (recon.retired_employee_b) {
      return "text_green";
    } else if (recon.spouse_b) {
      return "text_green";
    } else {
      return "text_green";
    }
  }
  reconRecordType(rec) {
    let type = "";
    if (rec.cur_student) {
      type += "Student";
    } else if (rec.current_employee_b) {
      type = `| ${type}` ? type : type
      type += "Employee";
    } else if (rec.record_type_id == "012d0000000s9QFAAY") {
      type = `| ${type}` ? type : type
      type += "business";
    } else if (rec.current_alumni_b) {
      type = `| ${type}` ? type : type
      type += "Alumni";
    } else if (rec.former_employee_b) {
      type = `| ${type}` ? type : type
      type += "Former Employee";
    } else if (rec.former_student_b) {
      type = `| ${type}` ? type : type
      type += "Former Student";
    } else if (rec.friend_b) {
      type = `| ${type}` ? type : type
      type += "Friend";
    } else if (rec.retired_employee_b) {
      type = `| ${type}` ? type : type
      type += "Retired Employee";
    } else if (rec.spouse_b) {
      type = `| ${type}` ? type : type
      type += "Spouse";
    } else {
      type = `| ${type}` ? type : type
      type += "Other";
    }
    return type
  }
  goBack(){
    this.router.navigate(['/batch-details']);
  }
  selectVal(item){
    this.selectedVal = item;
  }
}
