import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormArray } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { RunParam } from '../search-person/search-person';
import { ScheduleUpload } from './schedule-upload';
import { IdrSource } from './schedule-upload';
import { timeout, catchError } from 'rxjs/operators';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

const httpOptionsM = {
  headers: new HttpHeaders({ 'Content-Type': '' })
};
@Injectable({
  providedIn: 'root'
})
export class ScheduleUploadService {

constructor(
  private http: HttpClient
) { }

getScheduleUploadData(): Observable<ScheduleUpload[]> {
  let Url = '//api/idr/sources';
  return this.http.get<ScheduleUpload[]>(environment.serverUrl + Url)
    .pipe(map((data: ScheduleUpload[]) => {
      return data
    }));
}

getAllAsFormArray(): Observable<FormArray> {
  return this.getScheduleUploadData().pipe(map((scheduleUpload: ScheduleUpload[]) => {
    // Maps all the albums into a formGroup defined in tge album.model.ts
    const fgs = scheduleUpload.map(ScheduleUpload.asFormGroup);
    return new FormArray(fgs);
  }));
}

saveData(data: IdrSource, id: number): Observable<any> {
  let Url = '//api/idr/sources/';
  return this.http.put(environment.serverUrl + Url + id, data, httpOptions)
}

runService(salseforceid, from, to,api): Observable<any> {
  let Url = '/api/'+api;
  let Params = new HttpParams();
if(salseforceid){
  Params = Params.append('salesforce_id', String(salseforceid));
}
if(from){
  Params = Params.append('startDate', String(from));
}
if(to){
  Params = Params.append('endDate', String(to));
}
  return this.http.get(environment.serverUrl + Url,{params: Params})
}

uploadData(data: any): Observable<any> {
  let Url = '/api/idr_file_upload/create';
  return this.http.post(environment.serverUrl + Url,data);
}
executeData(data: any,filename,sourcename,api): Observable<any> {
  let Url = '/api/'+api+'?filename='+filename+'&source='+sourcename;
  return this.http.get(environment.serverUrl + Url,data);
}
// execiteData(data: any,filename,sourcename,api): Observable<any[]> {
//   let Url = '/api/'+api+'?filename='+filename+'&source='+sourcename;
//   return this.http.get<any[]>(environment.serverUrl + Url)
//     .pipe(timeout(10000)map((data: any[]) => {
//       return data
//     }));
// }
}



