<div class="container-fluid topPad">
    <div class="row">
        <div class="col-md-10">
            <h1>BATCH PROCESS - Matches</h1>
        </div>
        <div class="col-md-2">
            <button mat-raised-button class="matchBtn" color="primary" mat-dialog-close (click)="goBack()">Back to Batch Details</button>
        </div>
    </div>
    
    <div class="row topPad">
        <div class="col-md-4 ">
            <div class="font_20 "><span class="heading_color"><b>SOURCE :</b></span><span class="sub_color">
                    {{selectedSource}}</span></div>
            <div class="font_20 padTop20"><span class="heading_color"><b>PERSONAL INFORMATION</b></span></div>
            <div class="row padTop20  borderbox">
                <div class="col-md-4 padTop20">
                    <div class="font_16 "><b>First Name :</b> </div>
                    <div class="font_16 "><b>Last Name :</b> </div>
                    <div class="font_16 "><b>Email :</b> </div>
                </div>
                <div class="col-md-8 padTop20">
                    <div class="font_16 "> {{recordVal.input_record.first_name}}</div>
                    <div class="font_16 "> {{recordVal.input_record.last_name}}</div>
                    <div class="font_16 "> {{recordVal.input_record.email}}</div>
                </div>
            </div>
            <div class="font_20 padTop20"><span class="heading_color"><b>OTHER INFORMATION</b></span></div>
            <div class="row padTop20  borderbox">
                <div class="col-md-5 padTop20">
                    <div class="font_16 "><b>Source Name :</b> </div>
                </div>
                <div class="col-md-7 padTop20">
                    <div class="font_16 "> {{recordVal.input_record.details.source_name}}</div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="row">
                <div class="col-md-11">
                    <div class="font_16 matchHeader">Possible Matches in Salesforce</div>
                    <div *ngFor="let item of recordVal.matching_records; index as i">
                        <div class="matchDiv" [ngClass]="reconColor(item)" (click)="selectVal(item)">
                            <div><span>{{i+1}}. </span> <span>{{item.first_name}} {{item.last_name}} <span (click)="navSales(item)"><img src="./assets/images/sflogo.jpeg"></span></span></div>
                            <div>{{item.email}}</div>
                            <div><b>EMPL ID :</b> {{item.empl_id}}</div>
                            <div><b>TYPE:</b> {{reconRecordType(item)}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-5" *ngIf="selectedVal">
           
            <div class="font_20 padTop20"><span class="heading_color"><b>PERSONAL INFORMATION</b></span></div>
            <div class="row padTop20  borderbox">
                <div class="col-md-4 padTop20">
                    <div class="font_16 "><b>First Name :</b> </div>
                    <div class="font_16 "><b>Middle Name :</b> </div>
                    <div class="font_16 "><b>Last Name :</b> </div>
                    <div class="font_16 "><b>Email :</b> </div>
                </div>
                <div class="col-md-8 padTop20">
                    <div class="font_16 "> {{selectedVal.first_name ? selectedVal.first_name : '-'}}</div>
                    <div class="font_16 "> {{selectedVal.middle_name ? selectedVal.middle_name : '-'}}</div>
                    <div class="font_16 "> {{selectedVal.last_name ? selectedVal.last_name : '-'}}</div>
                    <div class="font_16 "> {{selectedVal.email ? selectedVal.email : '-'}}</div>
                </div>
            </div>
            <div class="font_20 padTop20"><span class="heading_color"><b>OTHER INFORMATION</b></span></div>
            <div class="row padTop20  borderbox">
                <div class="col-md-5 padTop20">
                    <div class="font_16 "><b>Empl ID :</b> </div>
                    <div class="font_16 "> <b>Account Name :</b></div>
                    <div class="font_16 "> <b>Birth Name :</b></div>
                    <div class="font_16 "> <b>DOB :</b></div>
                    <div class="font_16 "> <b>Gender :</b></div>
                    <div class="font_16 "> <b>Preferred Email :</b></div>
                    <div class="font_16 "> <b>Secondary Email :</b></div>
                    <div class="font_16 "> <b>City :</b></div>
                    <div class="font_16 "> <b>State :</b></div>
                    <div class="font_16 "> <b>Postal Code :</b></div>
                    <div class="font_16 "> <b>Country :</b></div>
                    <div class="font_16 "> <b>Mobile :</b></div>
                    <div class="font_16 "> <b>Work :</b></div>
                    <div class="font_16 "> <b>Employer :</b></div>
                    <div class="font_16 "> <b>Title :</b></div>
                    <div class="font_16 "> <b>Advance ID :</b></div>
                    <div class="font_16 "> <b>IModules ID :</b></div>
                    <div class="font_16 "> <b>Career Quest :</b></div>
                    <div class="font_16 "> <b>Miltary Status :</b></div>
                    <div class="font_16 "> <b>Cur Student :</b></div>
                    <div class="font_16 "> <b>Cur Alumni :</b></div>
                    <div class="font_16 "> <b>Miltary Branch :</b></div>
                    <div class="font_16 "> <b>Cur Employer :</b></div>
                    <div class="font_16 "> <b>Salesforce ID :</b></div>
                    <div class="font_16 "> <b>Prof Suffix :</b></div>
                    <div class="font_16 "> <b>Pers Suffix :</b></div>
                    <div class="font_16 "> <b>SALUTATION :</b></div>
                    <div class="font_16 "> <b>Entity Status :</b></div>
                    <div class="font_16 "> <b>Org Contact :</b></div>
                    <div class="font_16 "> <b>Record Type ID :</b></div>
                    <div class="font_16 "> <b>Record Type Name :</b></div>
                </div>
                <div class="col-md-7 padTop20">
                    <div class="font_16 "> {{selectedVal.empl_id? selectedVal.empl_id : '-'}}</div>
                    <div class="font_16 "> {{selectedVal.account_name? selectedVal.account_name : '-'}}</div>
                    <div class="font_16 "> {{selectedVal.birth_name? selectedVal.birth_name : '-'}}</div>
                    <div class="font_16 "> {{selectedVal.date_of_birth? selectedVal.date_of_birth : '-'}}</div>
                    <div class="font_16 "> {{selectedVal.gender? selectedVal.gender : '-'}}</div>
                    <div class="font_16 "> {{selectedVal.preferred_email? selectedVal.preferred_email : '-'}}</div>
                    <div class="font_16 "> {{selectedVal.secondary_email? selectedVal.secondary_email : '-'}}</div>
                    <div class="font_16 "> {{selectedVal.city? selectedVal.city : '-'}}</div>
                    <div class="font_16 "> {{selectedVal.state? selectedVal.state : '-'}}</div>
                    <div class="font_16 "> {{selectedVal.postal_code? selectedVal.postal_code : '-'}}</div>
                    <div class="font_16 "> {{selectedVal.country? selectedVal.country : '-'}}</div>
                    <div class="font_16 "> {{selectedVal.mobile_phone? selectedVal.mobile_phone : '-'}}</div>
                    <div class="font_16 "> {{selectedVal.work_phone? selectedVal.work_phone : '-'}}</div>
                    <div class="font_16 "> {{selectedVal.employer_name? selectedVal.employer_name : '-'}}</div>
                    <div class="font_16 "> {{selectedVal.title? selectedVal.title : '-'}}</div>
                    <div class="font_16 "> {{selectedVal.advance_id? selectedVal.advance_id : '-'}}</div>
                    <div class="font_16 "> {{selectedVal.imodules_id? selectedVal.imodules_id : '-'}}</div>
                    <div class="font_16 "> {{selectedVal.career_quest_id? selectedVal.career_quest_id : '-'}}</div>
                    <div class="font_16 "> {{selectedVal.military_status? selectedVal.military_status : '-'}}</div>
                    <div class="font_16 "> {{selectedVal.cur_student? selectedVal.cur_student : '-'}}</div>
                    <div class="font_16 "> {{selectedVal.current_alumni_b? selectedVal.current_alumni_b : '-'}}</div>
                    <div class="font_16 "> {{selectedVal.military_branch? selectedVal.military_branch : '-'}}</div>
                    <div class="font_16 "> {{selectedVal.current_employee_b? selectedVal.current_employee_b : '-'}}</div>
                    <div class="font_16 "> {{selectedVal.salesforce_id? selectedVal.salesforce_id : '-'}}</div>
                    <div class="font_16 "> {{selectedVal.prof_suffix? selectedVal.prof_suffix : '-'}}</div>
                    <div class="font_16 "> {{selectedVal.pers_suffix? selectedVal.pers_suffix : '-'}}</div>
                    <div class="font_16 "> {{selectedVal.salutation? selectedVal.salutation : '-'}}</div>
                    <div class="font_16 "> {{selectedVal.entity_status? selectedVal.entity_status : '-'}}</div>
                    <div class="font_16 "> {{selectedVal.organizational_contact? selectedVal.organizational_contact : '-'}}</div>
                    <div class="font_16 "> {{selectedVal.record_type_id? selectedVal.record_type_id : '-'}}</div>
                    <div class="font_16 "> {{selectedVal.record_type_name? selectedVal.record_type_name : '-'}}</div>
                </div>
            </div>
            <button mat-raised-button class="matchBtn" color="primary" mat-dialog-close *ngIf="showMatch" (click)="updateRecords(selectedVal)">Match</button>
        </div>
    </div>
</div>