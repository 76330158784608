export const behaviour = {
        sourcesConfig: {
            "imodules_forms": {
                "plural": "imodules_forms",
                "batchApi": "idr/imodules_forms/create_batch",
                "type": "activity",
                "display_name":"iModules Forms",
                "directory": "idr"
            },
            "imodules_events": {
                "plural": "imodules_events",
                "batchApi": "idr/imodules_events/create_batch",
                "type": "activity",
                "display_name": "iModules Events",
                "directory": "idr"
            },
            "im_members": {
                "plural": "im_members",
                "batchApi": "idr/im/members/create_batch",
                "type": "person",
                "directory": "idr"
            },
            "brazen": {
                "plural": "brazens",
                "batchApi": "idr/brazens/create_batch",
                "type": "activity",
                "directory": "idr"
            },
            "webex": {
                "plural": "webexes",
                "batchApi": "idr/webexes/create_batch",
                "type": "activity",
                "directory": "idr"
            },
            "zkipster": {
                "plural": "zkipsters",
                "batchApi": "idr/zkipsters/create_batch",
                "type": "activity",
                "directory": "idr"
            },
            "advance": {
                "plural": "advance",
                "batchApi": "idr/advance/create_batch",
                "type": "activity",
                "directory": "idr"
            },
            "advance_gifts": {
                "plural": "advance_gifts",
                "batchApi": "ia/advance_gifts/create_batch",
                "type": "activity",
                "directory": "ia"
            },
            "cq_job_postings": {
                "plural": "cq_job_postings",
                "batchApi": "idr/cq/job_postings/create_batch",
                "type": "activity",
                "directory": "idr"
            },
            "cq_job_applications": {
                "plural": "cq_job_applications",
                "batchApi": "idr/cq/job_applications/create_batch",
                "type": "activity",
                "directory": "idr"
            },
            "advance_degree": {
                "plural": "ps_degrees",
                "batchApi": "ia/ps_degrees/create_batch",
                "type": "activity",
                "directory": "ia"
            },
            "advance_helio_person": {
                "plural": "advance",
                "batchApi": "idr/advance/create_batch",
                "type": "activity",
                "directory": "ia"
            },
            "cq_activities": {
                "plural": "cq_activities",
                "batchApi": "idr/cq/activities/create_batch",
                "type": "activity",
                "directory": "idr"
            },
            "export_to_advance": {
                "plural": "export_to_advance",
                "batchApi": "ia/people/export",
                "type": "activity",
                "directory": "ia"
            },
            "sf_organization": {
                "plural": "organizations",
                "batchApi": "ia/organizations/create_batch",
                "type": "activity",
                "directory": "ia"
            },
            "career_quest": {
                "plural": "career_quest",
                "batchApi": "idr/career_quests/create_batch",
                "type": "person",
                "directory": "idr"
            },
            "sf_campaign": {
                "plural": "sf_campaign",
                "batchApi": "ia/campaigns/create_batch",
                "type": "activity",
                "directory": "ia"
            },
            "salesforce": {
                "plural": "salesforce",
                "batchApi": "/ia/people/create_batch",
                "type": "person",
                "directory": "ia"
            },
            "sf_address": {
                "plural": "sf_address",
                "batchApi": "/ia/addresses/create_batch",
                "type": "person",
                "directory": "ia"
            },
            "sf_phone": {
                "plural": "sf_phone",
                "batchApi": "/ia/phones/create_batch",
                "type": "person",
                "directory": "ia"
            },
            "sf_affiliation": {
                "plural": "sf_affiliation",
                "batchApi": "/ia/affiliations/create_batch",
                "type": "person",
                "directory": "ia"
            },
            "workday": {
                "plural": "workday",
                "batchApi": "/ia/workday/create_batch",
                "type": "activity",
                "directory": "ia"
            },
            "bulk": {
                "plural": "bulk",
                "batchApi": "/idr/bulk/create_batch",
                "type": "activity",
                "directory": "idr"
            },
            "dnc": {
                "plural": "dncs",
                "batchApi": "/idr/dncs/create_batch",
                "type": "activity",
                "directory": "idr"
            },
            "ps_degrees": {
                "plural": "ps_degrees",
                "batchApi": "ia/ps_degrees/create_batch",
                "type": "activity",
                "directory": "ia"
            },
            "event": {
                "plural": "events",
                "batchApi": "idr/events/create_batch",
                "type": "activity",
                "directory": "idr"
            },
            "zoom": {
                "plural": "zooms",
                "batchApi": "idr/zooms/create_batch",
                "type": "activity",
                "directory": "idr"
            },
            "gift_transactions": {
                "plural": "gift_transactions",
                "batchApi": "ia/gift_transactions/create_batch",
                "type": "activity",
                "directory": "ia"
            }

        },
        batchSingular: {
            value: {
            "imodules_forms": "imodules_forms",
            "imodules_events": "imodules_events",
            "im_members": "im_members",
            "career_quest": "career_quest",
            "brazens": "brazen",
            "webexes": "webex",
            "zkipsters": "zkipster",
            "cq_job_postings": "cq_job_postings",
            "cq_job_applications": "cq_job_applications",
            "cq_activities":"cq_activities",
            "advance_degree": "advance_degree",
            "advance_gifts": "advance_gifts",
            "bulk": "bulk",
            "dncs": "dnc",
            "advance": "advance",
            "ps_degrees":"advance_degree",
            "event":"event",
            "zooms": "zoom",
            "gift_transactions": "gift_transactions"
            }
        }
}