import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { PaginationParam } from '../batch-match/batch-match.component';
import { SearchParam } from './search-person';

@Injectable({
  providedIn: 'root'
})
export class SearchPersonService {

constructor(private http: HttpClient) { }

getPersonData(params: any): Observable<any> {
  let Params = new HttpParams();

  Params = Params.append('page', String(params.page));
  Params = Params.append('size', String(params.size));
  return this.http.get(environment.serverUrl + '//api/ia/people', {params : Params})
    .pipe(map(data => data));
}

getPersonProfile(salesForceId: any): Observable<any> {
  let Params = new HttpParams();
  Params = Params.append('salesforce_id', String(salesForceId));
  return this.http.get(environment.serverUrl + '//api/ia/people?', {params : Params})
    .pipe(map(data => data));
}

getSearchPerson(searchparams: SearchParam, params: PaginationParam): Observable<any> {
  let Params = new HttpParams();

  Params = Params.append('page', String(params.page));
  Params = Params.append('size', String(params.size));
  if(searchparams.first_name){
    Params = Params.append('first_name', String(searchparams.first_name));
  } if(searchparams.last_name){
    Params = Params.append('last_name', String(searchparams.last_name));
  }  if(searchparams.salesforce_id){
    Params = Params.append('salesforce_id', String(searchparams.salesforce_id));
  }  if(searchparams.empl_id){
    Params = Params.append('empl_id', String(searchparams.empl_id));
  }  if(searchparams.advance_id){
    Params = Params.append('advance_id', String(searchparams.advance_id));
  }  if(searchparams.imodules_id){
    Params = Params.append('imodules_id', String(searchparams.imodules_id));
  }  if(searchparams.career_quest_id){
    Params = Params.append('career_quest_id', String(searchparams.career_quest_id));
  }  if(searchparams.email){
    Params = Params.append('email', String(searchparams.email));
  }
  return this.http.get(environment.serverUrl + '//api/ia/people', {params : Params})
    .pipe(map(data => data));
}

}
