<div class="container-fluid topPad" id="searchPage">
<div>
    <h3>Search Person Record</h3>
</div>
<div class="row">
<div class="col-md-12">
  <!-- <form>
    <mat-form-field appearance="legacy">
      <mat-label>Search</mat-label>
      <input matInput placeholder="Search">
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
  </form> -->
</div>
</div>
<div class="row">
    <div class="col-md-12">
      <form [formGroup]="searchForm">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

          <ng-container matColumnDef="firstname">
            <th mat-header-cell *matHeaderCellDef> 
              <mat-form-field appearance="legacy">
                <mat-label class="matLabel">First Name</mat-label>
              <input matInput (keyup) = "getPeopleSearch()" [(ngModel)]="searchParams.first_name" name="firstName" formControlName="firstName">
            </mat-form-field> </th>
            <td mat-cell *matCellDef="let element" (click)="getProfile(element)"> {{element.first_name}}</td>
          </ng-container>
        
          <ng-container matColumnDef="lastname">
            <th mat-header-cell *matHeaderCellDef> 
              <mat-form-field appearance="legacy">
                <mat-label class="matLabel">Last Name</mat-label>
              <input matInput (keyup) = "getPeopleSearch()" [(ngModel)]="searchParams.last_name" name="lastName" formControlName="lastName">
            </mat-form-field> </th>
            <td mat-cell *matCellDef="let element" (click)="getProfile(element)"> {{element.last_name}} </td>
          </ng-container>
        
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef> 
              <mat-form-field appearance="legacy">
                <mat-label class="matLabel">Email</mat-label>
              <input matInput (keyup) = "getPeopleSearch()" [(ngModel)]="searchParams.email" name="email" formControlName="firstName">
            </mat-form-field> </th>
            <td mat-cell *matCellDef="let element" (click)="getProfile(element)"> {{element.email}} </td>
          </ng-container>
        
          <ng-container matColumnDef="salesforceid">
            <th mat-header-cell *matHeaderCellDef> 
              <mat-form-field appearance="legacy">
                <mat-label class="matLabel">SalesForce ID</mat-label>
              <input matInput (keyup) = "getPeopleSearch()" [(ngModel)]="searchParams.salesforce_id" name="salesforceid" formControlName="firstName">
            </mat-form-field> </th>
            <td mat-cell *matCellDef="let element"  (click)="navSales(element)"> {{element.salesforce_id}} <span ><img src="./assets/images/sflogo.jpeg"></span></td>
          </ng-container>

          <ng-container matColumnDef="emplid">
            <th mat-header-cell *matHeaderCellDef> 
              <mat-form-field appearance="legacy">
                <mat-label class="matLabel">Empl ID</mat-label>
              <input matInput (keyup) = "getPeopleSearch()" [(ngModel)]="searchParams.empl_id" name="emplid" formControlName="firstName">
            </mat-form-field> </th>
            <td mat-cell *matCellDef="let element" (click)="getProfile(element)"> {{element.empl_id}} </td>
          </ng-container>

          <ng-container matColumnDef="advanceid">
            <th mat-header-cell *matHeaderCellDef> 
              <mat-form-field appearance="legacy">
                <mat-label class="matLabel">Advance ID</mat-label>
              <input matInput (keyup) = "getPeopleSearch()" [(ngModel)]="searchParams.advance_id" name="advanceid" formControlName="firstName">
            </mat-form-field> </th>
            <td mat-cell *matCellDef="let element" (click)="getProfile(element)">{{element.advance_id}}</td>
          </ng-container>

          <ng-container matColumnDef="imodulusid">
            <th mat-header-cell *matHeaderCellDef> 
              <mat-form-field appearance="legacy">
                <mat-label class="matLabel">Imodules ID</mat-label>
              <input matInput (keyup) = "getPeopleSearch()" [(ngModel)]="searchParams.imodules_id" name="Imodulesid" formControlName="firstName">
            </mat-form-field> </th>
            <td mat-cell *matCellDef="let element" (click)="getProfile(element)">{{element.imodules_id}}</td>
          </ng-container>

          <ng-container matColumnDef="questid">
            <th mat-header-cell *matHeaderCellDef> 
              <mat-form-field appearance="legacy">
                <mat-label class="matLabel">Quest ID</mat-label>
              <input matInput (keyup) = "getPeopleSearch()" [(ngModel)]="searchParams.career_quest_id" name="careerQuestId" formControlName="firstName">
            </mat-form-field> </th>
            <td mat-cell *matCellDef="let element" (click)="getProfile(element)">{{element.career_quest_id}}</td>
          </ng-container>
        
          <tr mat-header-row *matHeaderRowDef="displayedColumns" ></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="hover" ></tr>
        </table>
      </form>
        
          <div class="row">
            <div class="col-12">
              <mat-paginator  [pageSize]="rowsPerPage"
              showFirstLastButtons [length]="totalElem" (page)="onPagination($event)" showFirstLastButtons
              id="admin-audit-history-pagination"></mat-paginator>
            </div>
    </div>
   
</div>
</div>
