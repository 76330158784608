import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ManualReconService {
  constructor(private http: HttpClient) { }

  getSourceData(api): Observable<any> {
    return this.http.get(environment.serverUrl + api)
      .pipe(map(data => data));
  }
  getTableRecords(source,sfId,params): Observable<any> {
    let Params = new HttpParams();
        Params = Params.append('salesforce_id',String(sfId))
        Params = Params.append('page', String(params.page));
        Params = Params.append('size', String(params.size));
        if(source){
          Params = Params.append('source', String(source));
        }
       
    return this.http.get(environment.serverUrl + '/api/idr/person_updates',{params : Params})
      .pipe(map(data => data));
  }

  

  getMapData(): Observable<any[]> {
    const params = new HttpParams()
    .set('InitialLoad', 'true')
    return this.http.get<any>(environment.serverUrl + '/api/Filter/FilterForMap?'+ params);
  }
  postUpdates(api): Observable<any> {
    return this.http
        .get(environment.serverUrl+ api, {});
}

}
