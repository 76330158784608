import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BatchDetailsComponent } from './batch-details/batch-details.component';
import { BatchMatchComponent } from './batch-match/batch-match.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ManualReconComponent } from './manual-recon/manual-recon.component';
import { ProfileDetailsComponent } from './profile-details/profile-details.component';
import { ProfileUpdatesComponent } from './profile-updates/profile-updates.component';
import { ScheduleUploadComponent } from './schedule-upload/schedule-upload.component';
import { SearchPersonComponent } from './search-person/search-person.component';


const routes: Routes = [
  {
    path: '',
    component: DashboardComponent
  },
  {
    path: 'batch-match',
    component:BatchMatchComponent
  },
  {
    path: 'contacts',
    component: SearchPersonComponent
  },
  {
    path: 'profile-updates',
    component : ProfileUpdatesComponent
  },
  {
    path: 'profile-details',
    component : ProfileDetailsComponent
  },
  
  {
    path: 'schedule-upload',
    component: ScheduleUploadComponent
  },
  {
    path: 'batch-details',
    component : BatchDetailsComponent
  },
  {
    path: 'manual-recon',
    component : ManualReconComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
