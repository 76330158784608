<mat-tab-group>
    <mat-tab label="Alumni & Others">
        <mat-form-field class="example-form-field">
            <mat-label>Search with Name</mat-label>
            <input matInput type="text" [(ngModel)]="value_alumni" (ngModelChange)=updateValAlumni($event)>
            <button mat-button *ngIf="value_alumni" matSuffix mat-icon-button aria-label="Clear"
                (click)="value_alumni=''">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
        <div class="row">
            <div class="col-md-12 col-12">
                <div class="faculty-summary-container mat-elevation-z2">
                    <table mat-table [dataSource]="alumnidataSource" *ngIf="this.alumniDataExists">
                        <ng-container matColumnDef="fname">
                            <th mat-header-cell *matHeaderCellDef> First Name </th>
                            <td mat-cell *matCellDef="let element" (click)="openDetail(element)">
                                {{element.person.first_name}} </td>
                        </ng-container>

                        <ng-container matColumnDef="lname">
                            <th mat-header-cell *matHeaderCellDef> Last Name </th>
                            <td mat-cell *matCellDef="let element" (click)="openDetail(element)">
                                {{element.person.last_name}} </td>
                        </ng-container>

                        <ng-container matColumnDef="email">
                            <th mat-header-cell *matHeaderCellDef> Email </th>
                            <td mat-cell *matCellDef="let element" (click)="openDetail(element)"> {{element.person.email
                                }} </td>
                        </ng-container>

                        <ng-container matColumnDef="updates">
                            <th mat-header-cell *matHeaderCellDef> Amount of Changes </th>
                            <td mat-cell *matCellDef="let element" (click)="openDetail(element)">
                                {{element.amount_of_changes}} </td>
                        </ng-container>
                        <ng-container matColumnDef="lastupdated">
                            <th mat-header-cell *matHeaderCellDef> Last Updated </th>
                            <td mat-cell *matCellDef="let element" (click)="openDetail(element)"><span>
                                    {{element.person.created_at | date:'short'}} </span></td>
                        </ng-container>
                        <ng-container matColumnDef="salesforce">
                            <th mat-header-cell *matHeaderCellDef> Salesforce ID </th>
                            <td mat-cell *matCellDef="let element" (click)="openDetail(element)">
                                <span>{{element.person.salesforce_id}}</span> </td>
                        </ng-container>
                        <ng-container matColumnDef="type">
                            <th mat-header-cell *matHeaderCellDef> Contact Type </th>
                            <td mat-cell *matCellDef="let element" (click)="openDetail(element)">
                                <span>{{getContactType(element.person)}}</span>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                        </tr>
                    </table>
                    <div *ngIf="!this.alumniDataExists" class="errorTxt">
                        {{alumniErrorMsg}}
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="this.alumniDataExists">
            <div class="col-12">
                <mat-paginator [pageSize]="rowsPerPage" showFirstLastButtons [length]="totalElemAlumni"
                    (page)="onPaginationAlumni($event)" showFirstLastButtons id="admin-audit-history-pagination"
                    [pageIndex]="alumniIndex">
                </mat-paginator>
            </div>
        </div>
    </mat-tab>
    <mat-tab label="Students & Employees">
        <mat-form-field class="example-form-field">
            <mat-label>Search with Name</mat-label>
            <input matInput type="text" [(ngModel)]="value_student" (ngModelChange)=updateValstudent($event)>
            <button mat-button *ngIf="value_student" matSuffix mat-icon-button aria-label="Clear"
                (click)="value_student=''">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
        <div class="row">
            <div class="col-md-12 col-12">
                <div class="faculty-summary-container mat-elevation-z2">
                    <table mat-table [dataSource]="otherdataSource" *ngIf="this.otherDataExists">
                        <ng-container matColumnDef="fname">
                            <th mat-header-cell *matHeaderCellDef> First Name </th>
                            <td mat-cell *matCellDef="let element"> {{element.person.first_name}} </td>
                        </ng-container>

                        <ng-container matColumnDef="lname">
                            <th mat-header-cell *matHeaderCellDef> Last Name </th>
                            <td mat-cell *matCellDef="let element"> {{element.person.last_name}} </td>
                        </ng-container>

                        <ng-container matColumnDef="email">
                            <th mat-header-cell *matHeaderCellDef> Email </th>
                            <td mat-cell *matCellDef="let element"> {{element.person.email }} </td>
                        </ng-container>

                        <ng-container matColumnDef="updates">
                            <th mat-header-cell *matHeaderCellDef> Amount of Changes </th>
                            <td mat-cell *matCellDef="let element"> {{element.amount_of_changes}} </td>
                        </ng-container>
                        <ng-container matColumnDef="lastupdated">
                            <th mat-header-cell *matHeaderCellDef> Last Updated </th>
                            <td mat-cell *matCellDef="let element"><span> {{element.person.created_at}} </span></td>
                        </ng-container>
                        <ng-container matColumnDef="salesforce">
                            <th mat-header-cell *matHeaderCellDef> Salesforce ID </th>
                            <td mat-cell *matCellDef="let element"><span>{{element.person.salesforce_id}}</span> </td>
                        </ng-container>
                        <ng-container matColumnDef="type">
                            <th mat-header-cell *matHeaderCellDef> Contact Type </th>
                            <td mat-cell *matCellDef="let element"><span>{{getContactType(element.person)}}</span>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns2; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns2;">
                        </tr>
                    </table>
                    <div *ngIf="!this.otherDataExists" class="errorTxt">
                        {{otherErrorMsg}}
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <mat-paginator [pageSize]="rowsPerPage" showFirstLastButtons [length]="totalElemOther"
                    [pageIndex]="otherIndex" (page)="onPaginationOther($event)" showFirstLastButtons
                    id="admin-audit-history-pagination">
                </mat-paginator>
            </div>
        </div>
    </mat-tab>
</mat-tab-group>