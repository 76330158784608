<div class="container-fluid topPad">
  <div class="row">
    <div class="col-md-9">
      <h1 class="">PROFILE DETAILS </h1>
    </div>
    <div class="col-md-3 button_pad">
      <button mat-raised-button color="primary" mat-dialog-close (click)="backToPrevious()"
        class="button_right ">Back</button>
    </div>
  </div>
  <div class="row">
    <div class="col-md-3">
      <mat-form-field appearance="fill" class="field_pad_right">
        <mat-label>Select Source</mat-label>
        <mat-select (selectionChange)="fetchRecord($event)">
          <mat-option [value]=0 selected>All</mat-option>
          <mat-option *ngFor="let source of sources" [value]="source">{{source.display_name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-9">
      <div><b>First Name : </b><span> {{firstName}}</span></div>
      <div><b>Last Name : </b><span> {{lastName}}</span></div>
      <div><b>Salesforce ID : </b><span><a (click)="navSales()">{{salesforceId}} <span><img
                src="./assets/images/sflogo.jpeg"></span></a> </span></div>
    </div>
  </div>


  <div class="row">
    <div class="col-md-12 col-12">
      <div class="faculty-summary-container mat-elevation-z2">
        <table mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="source">
            <th mat-header-cell *matHeaderCellDef> Source </th>
            <td mat-cell *matCellDef="let element" class="cursor_pointer"> {{element.updates_type}} </td>
          </ng-container>

          <ng-container matColumnDef="fname">
            <th mat-header-cell *matHeaderCellDef> First Name </th>
            <td mat-cell *matCellDef="let element" class="cursor_pointer"> {{firstName}} </td>
          </ng-container>

          <ng-container matColumnDef="lname">
            <th mat-header-cell *matHeaderCellDef> Last Name </th>
            <td mat-cell *matCellDef="let element" class="cursor_pointer"> {{lastName}} </td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef> Email </th>
            <td mat-cell *matCellDef="let element"> {{email}} </td>
          </ng-container>
          <ng-container matColumnDef="lastrun">
            <th mat-header-cell *matHeaderCellDef> Last Run </th>
            <td mat-cell *matCellDef="let element"><span *ngIf="element"><span *ngIf="element.updated_at"
                  class="cursor_pointer"> {{element.updated_at | date:'short'}} </span></span></td>
          </ng-container>
          <ng-container matColumnDef="field">
            <th mat-header-cell *matHeaderCellDef> Field Name </th>
            <td mat-cell *matCellDef="let element"><span *ngIf="element.field_name"
                class="cursor_pointer">{{element.field_name}}</span></td>
          </ng-container>
          <ng-container matColumnDef="old">
            <th mat-header-cell *matHeaderCellDef> Old Value </th>
            <td mat-cell *matCellDef="let element"><span *ngIf="element.old_value">{{element.old_value}}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="new">
            <th mat-header-cell *matHeaderCellDef> New Value </th>
            <td mat-cell *matCellDef="let element" class="cursor_pointer"><span
                *ngIf="element.new_value">{{element.new_value}} <span *ngIf="element.field_name == 'photo_url'"><img
                    src="../../assets/images/user.svg" (click)="showImg(element.new_value)"></span>
              </span> </td>
          </ng-container>
          <ng-container matColumnDef="used">
            <th mat-header-cell *matHeaderCellDef> Previously Used? </th>
            <td mat-cell *matCellDef="let element" class="cursor_pointer">
              <!-- <span *ngIf="element.more != 'null'"><span
                    *ngIf="element.more.is_old" >{{element.more.is_old}} </span></span> -->
            </td>
          </ng-container>
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox #ref (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
              </mat-checkbox>
            </td>
          </ng-container>



          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;">
          </tr>
        </table>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <mat-paginator [pageSize]="rowsPerPage" showFirstLastButtons [length]="totalElem" (page)="onPagination($event)"
        showFirstLastButtons id="admin-audit-history-pagination"></mat-paginator>
    </div>
  </div>
  <div class="row">
    <button mat-raised-button color="primary" mat-dialog-close (click)="updateRecords()"
      class="button_right">Update</button>
  </div>
</div>