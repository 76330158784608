<mat-dialog-content class="mat-typography">
<div class="row">
  <div class="col-md-12" >
    <h2>Profile Details</h2>
  <div>
    <mat-list *ngFor="let data of personRecord">
      <div class="row">
        <mat-list-item>
        <div class="col-md-6"><span class="fontWeight">Source: </span></div>
        <div class="col-md-6">{{data.source}}</div>
      </mat-list-item>
      </div>

      <div class="row">
        <mat-list-item>
        <div class="col-md-6"><span class="fontWeight">SalceForce: </span></div>
        <div class="col-md-6">{{data.salesforce_id}}</div>
      </mat-list-item>
      </div>
       
    </mat-list>
    </div>
    <hr/>
    <h3 style="font-weight: bolder;">Person Information: </h3>
    <div>
      <mat-list role="list" *ngFor="let data of personRecord">
        <div class="row">
          <mat-list-item>
          <div class="col-md-6"><span class="fontWeight">First Name: </span></div>
          <div class="col-md-6">{{data.first_name}}</div>
        </mat-list-item>
        </div>

        <div class="row">
          <mat-list-item>
          <div class="col-md-6"><span class="fontWeight">Middle Name: </span></div>
          <div class="col-md-6">{{data.middle_name}}</div>
        </mat-list-item>
        </div>

        <div class="row">
          <mat-list-item>
          <div class="col-md-6"><span class="fontWeight">Last Name: </span></div>
          <div class="col-md-6">{{data.last_name}}</div>
        </mat-list-item>
        </div>

        <div class="row">
          <mat-list-item>
          <div class="col-md-6"><span class="fontWeight">Email: </span></div>
          <div class="col-md-6">{{data.email}}</div>
        </mat-list-item>
        </div>
      </mat-list>
    </div>
    <hr/>
    <div>
      <h3 style="font-weight: bolder;">Other Information:</h3>
      <mat-list role="list" *ngFor="let data of personRecord">
        <div class="row">
          <mat-list-item>
          <div class="col-md-6"><span class="fontWeight">First Name: </span></div>
          <div class="col-md-6">{{data.first_name}}</div>
        </mat-list-item></div>
        <div class="row">
          <mat-list-item>
          <div class="col-md-6"><span class="fontWeight">Middle Name: </span></div>
          <div class="col-md-6">{{data.middle_name}}</div>
        </mat-list-item></div>
        <div class="row">
          <mat-list-item>
          <div class="col-md-6"><span class="fontWeight">Last Name: </span></div>
          <div class="col-md-6">{{data.last_name}}</div>
        </mat-list-item></div>
        <div class="row">
          <mat-list-item>
          <div class="col-md-6"><span class="fontWeight">Email: </span></div>
          <div class="col-md-6">{{data.email}}</div>
        </mat-list-item></div>
        <div class="row">
          <mat-list-item>
          <div class="col-md-6"><span class="fontWeight">Emp Id: </span></div>
          <div class="col-md-6">{{data.empl_id}}</div>
        </mat-list-item></div>
        <div class="row">
          <mat-list-item>
          <div class="col-md-6"><span class="fontWeight">Cur Alumni: </span></div>
          <div class="col-md-6">{{data.current_alumni_b}}</div>
        </mat-list-item></div>
        <div class="row">
          <mat-list-item>
          <div class="col-md-6"><span class="fontWeight">SalceForce Id: </span></div>
          <div class="col-md-6">{{data.salesforce_id}}</div>
        </mat-list-item></div>
        <div class="row">
          <mat-list-item>
          <div class="col-md-6"><span class="fontWeight">Prof Suffix: </span></div>
          <div class="col-md-6">{{data.prof_suffix}}</div>
        </mat-list-item></div>
        <div class="row">
          <mat-list-item>
          <div class="col-md-6"><span class="fontWeight">Pers Suffix: </span></div>
          <div class="col-md-6">{{data.pers_suffix}}</div>
        </mat-list-item></div>
        <div class="row">
          <mat-list-item>
          <div class="col-md-6"><span class="fontWeight">Salutation: </span></div>
          <div class="col-md-6">{{data.salutation}}</div>
        </mat-list-item></div>
        <div class="row">
          <mat-list-item>
          <div class="col-md-6"><span class="fontWeight">Entity Status: </span></div>
          <div class="col-md-6">{{data.entity_status}}</div>
        </mat-list-item>
        </div>
        <div class="row">
          <mat-list-item>
          <div class="col-md-6"><span class="fontWeight">Organizational Contact: </span></div>
          <div class="col-md-6">{{data.organizational_contact}}</div>
        </mat-list-item>
</div>
        <div class="row">
          <mat-list-item>
          <div class="col-md-6"><span class="fontWeight">Record Type id: </span></div>
          <div class="col-md-6">{{data.record_type_id}}</div>
        </mat-list-item>
        </div>
        <div class="row">
          <mat-list-item>
          <div class="col-md-6"><span class="fontWeight">Record Type Name: </span></div>
          <div class="col-md-6">{{data.record_type_name}}</div>
        </mat-list-item>
        </div>
      </mat-list>
    </div>
  </div>


 
</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
<div>
  <button mat-raised-button color="primary" (click) = "onNoClick()">close</button>
</div>
</mat-dialog-actions>