import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class BatchMatchService {
  constructor(private http: HttpClient) { }

  getSourceData(): Observable<any> {
    return this.http.get(environment.serverUrl + '/api/idr/sources')
      .pipe(map(data => data));
  }
  getTableRecords(source,from,to,params): Observable<any> {
    let Params = new HttpParams();

        Params = Params.append('page', String(params.page));
        Params = Params.append('size', String(params.size));
        if(source){
          Params = Params.append('source', String(source));
        }
        if(from){
          Params = Params.append('from_date',String(from));
        }
        if(to){
          Params = Params.append('to_date',String(to));
        }
    return this.http.get(environment.serverUrl + '/api/idr/batches',{params : Params})
      .pipe(map(data => data));
  }
  getTableRecordsSort(source,from,to,params): Observable<any> {
    let Params = new HttpParams();

        Params = Params.append('page', String(params.page));
        Params = Params.append('size', String(params.size));
        if(source){
          Params = Params.append('source', String(source));
        }
        if(from){
          Params = Params.append('from_date',String(from));
        }
        if(to){
          Params = Params.append('to_date',String(to));
        }
        Params = Params.append('sort_field',String(params.sort)); 
        Params = Params.append('sort_direction',String(params.dir)); 
    return this.http.get(environment.serverUrl + '/api/idr/batches',{params : Params})
      .pipe(map(data => data));
  }
  

  getMapData(): Observable<any[]> {
    const params = new HttpParams()
    .set('InitialLoad', 'true')
    return this.http.get<any>(environment.serverUrl + '/api/Filter/FilterForMap?'+ params);
  }

  getInformation(batchId): Observable<any> {
    return this.http.get<any>(environment.serverUrl + '/api/idr/batches/' + batchId);
  }
  bulkUpdate(id): Observable<any> {
    return this.http
      .post(environment.serverUrl + '/api/idr/batches/accept_all/'+ id , {});
  }
}
