import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable, Subject, of } from 'rxjs';
import { SpinnerService } from '../spinner.service';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class BasicInterceptor implements HttpInterceptor {
    myUrl: string;
    
    constructor(private spinnerService: SpinnerService) {
        let sessionAuth;
    sessionAuth = sessionStorage.getItem("loginCookie");
    let clientId = environment.clientId;
    let ssoURL = environment.ssoUrl;
    let scope = 'openid';
    let responseType = 'token';
    let responseMode = 'fragment';
    let state = '12345';
    let nonce = '678910';
    let returnURL = encodeURI(environment.clientUrl);
    this.myUrl = `${ssoURL}?client_id=${clientId}&scope=${scope}&response_type=${responseType}&redirect_uri=${returnURL}&response_mode=${responseMode}&state=${state}&nonce=${nonce}`;
    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.spinnerService.show();
        const token = sessionStorage.getItem('loginCookie');
        request = request.clone({
            headers: request.headers.set('idrloginticket', token)
        });
        return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                this.spinnerService.hide();
            }
        }, (error) => {
            this.spinnerService.hide();
            if (error.status === 401) {
                sessionStorage.removeItem('loginCookie');
                window.location.href = this.myUrl;
            }
        }));
    }
}
