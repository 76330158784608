import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BatchMatchComponent } from './batch-match/batch-match.component';
import { HeaderComponent } from './header/header.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ProfileUpdatesComponent } from './profile-updates/profile-updates.component';
import { SearchPersonComponent } from './search-person/search-person.component';
import { ScheduleUploadComponent } from './schedule-upload/schedule-upload.component';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatTableModule} from '@angular/material/table'
import { NgxMatDateAdapter, NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BasicInterceptor } from './auth/basic.interceptor';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MaterialModule } from './material.module';
import { SearchPersonDialogComponent } from './search-person-dialog/search-person-dialog.component';
import { FooterComponent } from './footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RunDialogComponent } from './run-dialog/run-dialog.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { DatePipe } from '@angular/common';
import { BatchDetailsComponent } from './batch-details/batch-details.component';
import { ProfileDetailsComponent } from './profile-details/profile-details.component';
import { SearchMatchComponent } from './batch-details/search-match/search-match.component';
import { ManualReconComponent } from './manual-recon/manual-recon.component';
import { InformationComponent } from './batch-match/information/information.component';


@NgModule({
  declarations: [								
    AppComponent,
      DashboardComponent,
      BatchMatchComponent,
      HeaderComponent,
      ProfileUpdatesComponent,
      SearchPersonComponent,
      ScheduleUploadComponent,
      SearchPersonDialogComponent,
      FooterComponent,
      RunDialogComponent,
      SpinnerComponent,
      BatchDetailsComponent,
      ProfileDetailsComponent,
      SearchMatchComponent,
      ManualReconComponent,
      InformationComponent
   ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    MatToolbarModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    NgxMatMomentModule,
    HttpClientModule,
    MatTableModule,
    MatPaginatorModule,
    MaterialModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: BasicInterceptor, multi: true},
    DatePipe
  ],
  bootstrap: [AppComponent],
  entryComponents: [SearchPersonDialogComponent, InformationComponent,SearchMatchComponent]
})
export class AppModule { }
