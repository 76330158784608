import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';
@Component({
  selector: 'app-search-person-dialog',
  templateUrl: './search-person-dialog.component.html',
  styleUrls: ['./search-person-dialog.component.css']
})
export class SearchPersonDialogComponent implements OnInit {
  personRecord: any[] = [];
  sales_id : string;
  constructor(
    @Inject(MAT_DIALOG_DATA)public inputParam: any,
    public dialogRef: MatDialogRef<SearchPersonDialogComponent>){
      this.personRecord = this.inputParam;
   }
  ngOnInit() {
    
  }
  
  onNoClick(): void {
    this.dialogRef.close();
  }
}
