import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class BatchDetailService {
  constructor(private http: HttpClient) { }

  getSourceData(): Observable<any> {
    return this.http.get(environment.serverUrl + '/api/idr/sources')
      .pipe(map(data => data));
  }
  getTableRecords(type,id,batch,params): Observable<any> {
    let Params = new HttpParams();

        Params = Params.append('page', String(params.page));
        Params = Params.append('size', String(params.size));
        if(params.reconciled > 0){
          Params = Params.append('reconciled',String(params.reconciled));
        }
        
        
        // if(source){
        //   Params = Params.append('source', String(source));
        // }
        // if(from){
        //   Params = Params.append('from_date',String(from));
        // }
        // if(to){
        //   Params = Params.append('to_date',String(to));
        // }
        let typeDef = type.batchApi
        let typeApi = typeDef.substring(0, typeDef.length - 13);
    return this.http.get(environment.serverUrl + '/api/'+ typeApi +'?&batch_id='+ batch +'&controller=idr/batches&id='+ id +'',{params : Params})
      .pipe(map(data => data));
  }
  getTableRecordsSort(type,id,batch,params): Observable<any> {
    let Params = new HttpParams();

        Params = Params.append('page', String(params.page));
        Params = Params.append('size', String(params.size));
        Params = Params.append('sort_field',String(params.sort)); 
        Params = Params.append('sort_direction',String(params.dir)); 
        // if(source){
        //   Params = Params.append('source', String(source));
        // }
        // if(from){
        //   Params = Params.append('from_date',String(from));
        // }
        // if(to){
        //   Params = Params.append('to_date',String(to));
        // }
    return this.http.get(environment.serverUrl + '/api/'+ type.directory +'/'+ type.plural +'?actions='+type.type+'&batch_id='+ batch +'&controller=idr/batches&id='+ id +'',{params : Params})
      .pipe(map(data => data));
  }
  

  getMapData(): Observable<any[]> {
    const params = new HttpParams()
    .set('InitialLoad', 'true')
    return this.http.get<any>(environment.serverUrl + '/api/Filter/FilterForMap?'+ params);
  }

}
