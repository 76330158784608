import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ProfileUpdateService {
  constructor(private http: HttpClient) { }

  getAlumniData(params, alumniSearch): Observable<any> {
    let Params = new HttpParams();

    Params = Params.append('page', String(params.page));
    if (alumniSearch) {
      Params = Params.append('name', String(alumniSearch));
    }
    return this.http.get(environment.serverUrl + '/api/idr/person_updates/updates_for_people?editable_only=true', { params: Params })
      .pipe(map(data => data));
  }
  getStudentData(params, studentSearch): Observable<any> {
    let Params = new HttpParams();

    Params = Params.append('page', String(params.page));
    if (studentSearch) {
      Params = Params.append('name', String(studentSearch));
    }
    return this.http.get(environment.serverUrl + '/api/idr/person_updates/updates_for_people?editable_only=false', { params: Params })
      .pipe(map(data => data));
  }
  getTableRecords(source, from, to, params): Observable<any> {
    let Params = new HttpParams();

    Params = Params.append('page', String(params.page));
    Params = Params.append('size', String(params.size));
    if (source) {
      Params = Params.append('source', String(source));
    }
    if (from) {
      Params = Params.append('from_date', String(from));
    }
    if (to) {
      Params = Params.append('to_date', String(to));
    }
    return this.http.get(environment.serverUrl + '/api/idr/batches', { params: Params })
      .pipe(map(data => data));
  }



  getMapData(): Observable<any[]> {
    const params = new HttpParams()
      .set('InitialLoad', 'true')
    return this.http.get<any>(environment.serverUrl + '/api/Filter/FilterForMap?' + params);
  }

}
