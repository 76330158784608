import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationExtras, Router } from '@angular/router';
import { Updates } from './profile';
import { ProfileUpdateService } from './profile-updates.service';
import Swal from 'sweetalert2';
export class PaginationParam {
  page: number;
  size: number; //number of rows per page

  constructor(pageNbr: number, noOfItems: number) {
    this.page = pageNbr;
    this.size = noOfItems;
  }
}
@Component({
  selector: 'app-profile-updates',
  templateUrl: './profile-updates.component.html',
  styleUrls: ['./profile-updates.component.css']
})
export class ProfileUpdatesComponent implements OnInit {
  value_alumni = "";
  value_student = "";
  @ViewChild(MatPaginator) paginator: MatPaginator;
  pageSizeOptions: number[] = [];
  pageNumberAlumni: number = 1;
  pageNumberOther: number = 1;
  totalElemAlumni: number = 5;
  totalElemOther: number = 5;
  alumniIndex: number = 0;
  otherIndex: number = 0;
  rowsPerPage: number = 40;
  displayedColumns: string[] = ['fname', 'lname', 'email', 'updates', 'lastupdated', 'salesforce', 'type'];
  displayedColumns2: string[] = ['fname', 'lname', 'email', 'updates', 'lastupdated', 'salesforce', 'type'];
  alumnidataSource = new MatTableDataSource<Updates[]>();
  otherdataSource = new MatTableDataSource<Updates[]>();
  alumniDataExists: boolean = true;
  otherDataExists: boolean = true;
  alumniErrorMsg: string = '';
  otherErrorMsg: string = '';
  constructor(private profileUpdateService: ProfileUpdateService, private router: Router) { }

  ngOnInit(): void {

    if (sessionStorage.getItem('AlumniPage') != null && (sessionStorage.getItem('OthersPage') == null)) {
      this.pageNumberAlumni = parseInt(sessionStorage.getItem('AlumniPage'));
      let params: PaginationParam = new PaginationParam(parseInt(sessionStorage.getItem('AlumniPage')), this.rowsPerPage);
      let params1: PaginationParam = new PaginationParam(1, this.rowsPerPage);
      this.alumniIndex = parseInt(sessionStorage.getItem('AlumniPage')) - 1;
      this.otherIndex = 0;
      this.getAlumniRecords(params);
      this.getOtherRecords(params1);
    }
    if (sessionStorage.getItem('OthersPage') != null && (sessionStorage.getItem('AlumniPage') == null)) {
      this.pageNumberOther = parseInt(sessionStorage.getItem('OthersPage'));
      let params: PaginationParam = new PaginationParam(parseInt(sessionStorage.getItem('OthersPage')), this.rowsPerPage);
      let params1: PaginationParam = new PaginationParam(1, this.rowsPerPage);
      this.alumniIndex = 0;
      this.otherIndex = parseInt(sessionStorage.getItem('OthersPage')) - 1;
      this.getOtherRecords(params);
      this.getAlumniRecords(params1);
    }
    if ((sessionStorage.getItem('AlumniPage') != null) && (sessionStorage.getItem('OthersPage') != null)) {
      this.pageNumberAlumni = parseInt(sessionStorage.getItem('AlumniPage'));
      this.pageNumberOther = parseInt(sessionStorage.getItem('OthersPage'));
      let params: PaginationParam = new PaginationParam(parseInt(sessionStorage.getItem('AlumniPage')), this.rowsPerPage);
      let params1: PaginationParam = new PaginationParam(parseInt(sessionStorage.getItem('OthersPage')), this.rowsPerPage);
      this.alumniIndex = parseInt(sessionStorage.getItem('AlumniPage')) - 1;
      this.otherIndex = parseInt(sessionStorage.getItem('OthersPage')) - 1;
      this.getAlumniRecords(params);
      this.getOtherRecords(params1);
    }
    if ((sessionStorage.getItem('AlumniPage') == null) && (sessionStorage.getItem('OthersPage') == null)) {
      this.pageNumberAlumni = 1;
      this.pageNumberOther = 1;
      let params: PaginationParam = new PaginationParam(1, this.rowsPerPage);
      this.alumniIndex = 0;
      this.otherIndex = 0;
      this.getAlumniRecords(params);
      this.getOtherRecords(params);
    }

  }
  getAlumniRecords(params) {
    this.profileUpdateService.getAlumniData(params, this.value_alumni).subscribe(data => {
      if (data.message) {
        this.alumniDataExists = false;
        this.alumniErrorMsg = data.message;
      } else {
        this.alumnidataSource = data.records;
        this.totalElemAlumni = data.totalCount;
        this.alumniDataExists = true;
        this.alumniErrorMsg = '';
      }

    });
  }
  getOtherRecords(params) {
    this.profileUpdateService.getStudentData(params, this.value_student).subscribe(data => {
      if (data.message) {
        this.otherDataExists = false;
        this.otherErrorMsg = data.message;
      } else {
        this.otherDataExists = true;
        this.otherErrorMsg = '';
        this.otherdataSource = data.records;
        this.totalElemOther = data.totalCount;
      }

    })
  }
  updateValAlumni(eve) {
    if (eve.length > 3) {
      let params: PaginationParam = new PaginationParam(this.pageNumberAlumni, this.paginator.pageSize);
      this.getAlumniRecords(params)
    }

  }
  updateValstudent(eve) {
    if (eve.length > 3) {
      let params: PaginationParam = new PaginationParam(this.pageNumberOther, this.paginator.pageSize);
      this.getOtherRecords(params)
    }

  }
  onPaginationAlumni(event: any) {
    this.pageNumberAlumni = event.pageIndex + 1;
    this.alumniIndex = event.pageIndex;
    sessionStorage.setItem("AlumniPage", JSON.stringify(this.pageNumberAlumni));
    let params: PaginationParam = new PaginationParam(this.pageNumberAlumni, this.paginator.pageSize);
    this.getAlumniRecords(params)
  }
  onPaginationOther(event: any) {
    this.pageNumberOther = event.pageIndex + 1;
    this.otherIndex = event.pageIndex;
    sessionStorage.setItem("OthersPage", JSON.stringify(this.pageNumberOther));
    let params: PaginationParam = new PaginationParam(this.pageNumberOther, this.paginator.pageSize);
    this.getOtherRecords(params)
  }
  getContactType(reconciled) {
    if (reconciled.cur_student) {
      return "Student";
    } else if (reconciled.current_employee_b) {
      return "Employee";
    } else if (reconciled.record_type_id == "012d0000000s9QFAAY") {
      return "Business"
    } else if (reconciled.current_alumni_b) {
      return "Alumni";
    } else if (reconciled.former_employee_b) {
      return "Former Employee";
    } else if (reconciled.former_student_b) {
      return "Former Student";
    } else if (reconciled.friend_b) {
      return "Friend";
    } else if (reconciled.retired_employee_b) {
      return "Retired Employee";
    } else if (reconciled.spouse_b) {
      return "Spouse";
    } else {
      return "Other";
    }
  }

  openDetail(ele) {
    sessionStorage.setItem("AlumniPage", JSON.stringify(this.pageNumberAlumni));
    sessionStorage.setItem("OthersPage", JSON.stringify(this.pageNumberOther));
    const navigationExtras: NavigationExtras = {
      queryParams: {
        salesforce_id: ele.person.salesforce_id
      }
    };
    this.router.navigate(['/profile-details'], navigationExtras);
  }
  showImg(url) {
    Swal.fire({
      imageUrl: url,
      imageHeight: 400,
      imageWidth: 400,
      showConfirmButton: false
    })
  }

}
