<div *ngIf="fromData">
  <mat-dialog-content align="center">
    <div class="row">
      <div class="col-md-6">
        <mat-label><b>From Date and Time</b></mat-label>
        <mat-form-field appearance="outline" class="field_pad_right">
          <input matInput [ngxMatDatetimePicker]="picker2" placeholder="Choose a Start date"
            (dateChange)="startDateChange($event)">
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker #picker2>
          </ngx-mat-datetime-picker>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-label><b>To Date and Time</b></mat-label>
        <mat-form-field appearance="outline" class="field_pad_right" floatLabel="never">
          <input matInput [ngxMatDatetimePicker]="picker1" placeholder="Choose a End date"
            (dateChange)="endDateChange($event)">
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker #picker1>
          </ngx-mat-datetime-picker>
        </mat-form-field>
      </div>
    </div>
    <div>
      <mat-form-field class="optionalTxt">
        <input matInput type="text" [(ngModel)]="salseForceId" name="salseForceId" placeholder="Enter Optional SalseForce Id">
      </mat-form-field>
    </div>
    <div>
      <p class="textColor">
      ***NOTE***: This is only available for imodules career quest members batches
      </p>
    </div>
  </mat-dialog-content>
  <br/>
  <mat-dialog-actions align="end">
    <button mat-raised-button color="primary" mat-dialog-close>Close</button>
    <button mat-raised-button color="primary" (click)="executeData()" [disabled] = "checkDisabled()">Execute</button>
  </mat-dialog-actions>
</div>

<div *ngIf="!fromData">
  <form [formGroup] = "uploadForm" (ngSubmit)="onSubmit()">
  <mat-dialog-content align="center">
      <div>
        <input style="display: none" #fileInput id="input-file-id" type="file" name="profile" (change)="onFileSelect($event)"/>
        <button mat-stroked-button type="button" color="primary" id="file-select-button" class="mr-2 mb-1 button-padding button-margin" (click)="onClickFileInputButton()">
          <img src="./assets/images/upload.svg">
          Upload
        </button>
      </div>
      <div class="textCenter"><h4>{{fileName}}</h4></div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-raised-button color="primary" type="button" mat-dialog-close>Close</button>
    <button mat-raised-button color="primary" type="button" (click)="execute()">Execute</button>
  </mat-dialog-actions>
</form>
</div>
