<div class="site">
  <div class="content">
    <mat-toolbar >
      <mat-toolbar-row>
        <a class="navbar-brand" href="#">
          
          <img class="logoTop" id="logo" src="./assets/images/umgc-logo-website-rgb-desktop.svg" alt="UMGC">
        </a>  
        <span class="example-spacer"></span>
        <div>
          <a mat-button (click) = "gotoPages('bW')" class="colorBlack"> Batch & Match </a>
          <a mat-button (click) = "gotoPages('Pu')" class="colorBlack"> Profile Updates </a>
          <a mat-button (click) = "gotoPages('sPr')" class="colorBlack"> Search Person Record </a>
          <a mat-button (click) = "gotoPages('Su')" class="colorBlack"> Schedule & Upload </a>

        </div>
      </mat-toolbar-row>
    </mat-toolbar>
    <app-spinner></app-spinner>
    <router-outlet></router-outlet>
  </div>
  <app-footer fxFlexOffset="auto"></app-footer>
</div>