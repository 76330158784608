import { EmailValidator } from "@angular/forms";

export interface PersonSearch {
    firstName: string;
    lastName: string;
    email: string;
    salseforceid: string;
    emplid: string;
    advanceid: string;
    imodulusid: string;
    questid: string;
  }

  export class PaginationParam {
    page: number;
    size: number; //number of rows per page
  
    constructor(pageNbr: number, noOfItems: number) {
        this.page = pageNbr;
        this.size = noOfItems;
    }
  }

  export class RunParam{
    fromDate: any;
    toDate: any;

    constructor(fromDate: any, toDate: any) {
      this.fromDate = fromDate;
      this.toDate = toDate;
    }
  }
  
  export class SearchParam {
    pagination: PaginationParam;
    first_name?: string;
    last_name?: string;
    salesforce_id?: string;
    empl_id?: string;
    advance_id?: string;
    imodules_id?: string;
    career_quest_id?: string;
    email?: EmailValidator;
  }

  