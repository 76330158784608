import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
//import { TestServiceService } from './test-service.service';
import { BatchMatchService } from './batch-match.service';
import { MatPaginator } from '@angular/material/paginator';
import { BatchResult } from './batch-match';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { InformationComponent } from './information/information.component';
import Swal from 'sweetalert2';
import * as _ from 'lodash';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}
export class PaginationParam {
  page: number;
  size: number; //number of rows per page

  constructor(pageNbr: number, noOfItems: number) {
      this.page = pageNbr;
      this.size = noOfItems;
  }
}
export class PaginationParamSort {
  page: number;
  size: number; //number of rows per page
  sort: string = '';
  dir: string = '';

  constructor(pageNbr: number, noOfItems: number, sortString: string, dirString: string) {
      this.page = pageNbr;
      this.size = noOfItems;
      this.sort = sortString;
      this.dir = dirString;
  }
}
const ELEMENT_DATA: PeriodicElement[] = [
  { position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H' },
  { position: 2, name: 'Helium', weight: 4.0026, symbol: 'He' },
  { position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li' },
  { position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be' },
  { position: 5, name: 'Boron', weight: 10.811, symbol: 'B' },
  { position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C' },
  { position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N' },
  { position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O' },
  { position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F' },
  { position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne' },
  { position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H' },
  { position: 2, name: 'Helium', weight: 4.0026, symbol: 'He' },
  { position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li' },
  { position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be' },
  { position: 5, name: 'Boron', weight: 10.811, symbol: 'B' },
  { position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C' },
  { position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N' },
  { position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O' },
  { position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F' },
  { position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne' },
];

@Component({
  selector: 'app-batch-match',
  templateUrl: './batch-match.component.html',
  styleUrls: ['./batch-match.component.css']
})
export class BatchMatchComponent implements OnInit {
  batchIndex: number = 0;
  pageSizeOptions: number[] = [];
  totalElem: number = 5;
  displayedColumns: string[] = ['id', 'source', 'created_at', 'imported', 'errored', 'saved', 'success', 'multiple', 'nomatch', 'pending'];
  //dataSource = ELEMENT_DATA;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource = new MatTableDataSource<BatchResult[]>();
  fromDate: Date ;
  endDate: Date;
  sources: any[] = [];
  selectedSource: any = 0;
  rowsPerPage: number = 40;
  source = new FormControl();
  pageNumber: number = 1;
  sortEvent: any = null;
  sessionObj:any;
  toppingList: string[] = ['All', 'Advance Degree', 'Advance Gifts', 'Advance Members ', 'Brazen', 'Bulk', 'CareerQuest Members', 'CareerQuest Activities'];
  constructor(
    private batchService: BatchMatchService,
    private router: Router,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    //this.dataSource = new MatTableDataSource(ELEMENT_DATA);
    
    this.getSources();
   
    this.pageSizeOptionsCalculation(ELEMENT_DATA.length)
  }
  ngAfterViewInit() {
    
    this.dataSource.paginator = this.paginator;
    
  }
  checkSession(){
    this.sessionObj = JSON.parse(sessionStorage.getItem('batchSession'));
    if(this.sessionObj){
      if(this.sessionObj.source && this.sessionObj.source != 0){
        this.selectedSource = this.sessionObj.source;
      }
       if(this.sessionObj.pageNumber){
        this.pageNumber = this.sessionObj.pageNumber;
        this.batchIndex = this.pageNumber - 1;
      }
       if(this.sessionObj.sort){
        this.sortEvent = this.sessionObj.sort;
      }
       if(this.sessionObj.fromDate){
        this.fromDate = this.sessionObj.fromDate;
      }
       if(this.sessionObj.toDate){
        this.endDate = this.sessionObj.toDate;
      }
      let params: PaginationParam = new PaginationParam(this.pageNumber, this.paginator.pageSize);
      this.fetchTableRecords(params);
    }
   
    else{
      let params: PaginationParam = new PaginationParam(1, this.rowsPerPage);
      this.fetchTableRecords(params);
    }
  }
  getSources() {
    this.batchService.getSourceData().subscribe(data => {
      this.sources = data;
      this.checkSession()
    })
  }
  onSortData(event: any) {
    this.sortEvent = event;
    setTimeout(() => {
        const params: PaginationParamSort = new PaginationParamSort(1, this.paginator.pageSize, event.active, event.direction);
        this.fetchTableRecordsSort( params);
    });
}

  fetchRecord(eve) {
    this.pageNumber = 1;
    this.batchIndex = 0;
    this.fromDate = null;
    this.endDate = null;
    let selectedOption = _.find(this.sources, function(o) {
      if(o.id === eve.value){
        return o;
      }
  });
    this.selectedSource = selectedOption;
    if(this.sortEvent){
      const params: PaginationParamSort = new PaginationParamSort(1, this.paginator.pageSize, this.sortEvent.active, this.sortEvent.direction);
      this.fetchTableRecordsSort( params);
    }else{
      let params: PaginationParam = new PaginationParam(this.pageNumber, this.paginator.pageSize);
      this.fetchTableRecords(params);
    }
    
  }
  startDateChange(eve) {
    this.pageNumber = 1;
    this.batchIndex = 0;
    this.fromDate = eve.value._d;
    if(this.sortEvent){
      const params: PaginationParamSort = new PaginationParamSort(1, this.paginator.pageSize, this.sortEvent.active, this.sortEvent.direction);
      this.fetchTableRecordsSort( params);
    }else{
      let params: PaginationParam = new PaginationParam(this.pageNumber, this.paginator.pageSize);
      this.fetchTableRecords(params);
    }
  }
  endDateChange(eve) {
    this.pageNumber = 1;
    this.batchIndex = 0;
    this.endDate = eve.value._d;
    if(this.sortEvent){
      const params: PaginationParamSort = new PaginationParamSort(1, this.paginator.pageSize, this.sortEvent.active, this.sortEvent.direction);
      this.fetchTableRecordsSort( params);
    }else{
      let params: PaginationParam = new PaginationParam(this.pageNumber, this.paginator.pageSize);
      this.fetchTableRecords(params);
    }
  }
  fetchTableRecords(params) {
    //let params: PaginationParam = new PaginationParam(this.pageNumber, this.paginator.pageSize);
    
    this.batchService.getTableRecords(this.selectedSource.name,this.fromDate,this.endDate,params).subscribe(data => {
      this.dataSource = new MatTableDataSource(data.records);
      this.totalElem = data.totalCount;
      this.pageSizeOptionsCalculation(data.totalCount);
    })
  }
  fetchTableRecordsSort(params) {
    //let params: PaginationParam = new PaginationParam(this.pageNumber, this.paginator.pageSize);
    
    this.batchService.getTableRecordsSort(this.selectedSource.name,this.fromDate,this.endDate,params).subscribe(data => {
      this.dataSource = new MatTableDataSource(data.records);
      this.totalElem = data.totalCount;
      this.pageSizeOptionsCalculation(data.totalCount);
    })
  }
  ngOnDestroy(){
    let batchPageObj = {
      "fromDate" : this.fromDate,
      "toDate" : this.endDate,
      "source" : this.selectedSource,
      "pageNumber" : this.pageNumber,
      "sort" : this.sortEvent
    }
    sessionStorage.setItem("batchSession", JSON.stringify(batchPageObj));
  }
  pageSizeOptionsCalculation(total: number) {
    const defaultPageArray: number[] = [5, 10, 25, 50, 100, 250, 500, total];
    this.pageSizeOptions = [];
    this.pageSizeOptions = defaultPageArray.filter(x => x <= total);
  }
  onPagination(event: any) {
    this.pageNumber = event.pageIndex + 1;
    this.batchIndex = event.pageIndex;
    let params: PaginationParam = new PaginationParam(this.pageNumber, this.paginator.pageSize);
    this.fetchTableRecords(params);
  }
  openDetail(elem){
    this.router.navigateByUrl('/batch-details');
    localStorage.setItem('selectedBatch',JSON.stringify(elem));
  }
  openInfoDialog(id){
    sessionStorage.setItem('batchId', id)
    const dialogRef = this.dialog.open(InformationComponent, {
     
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  bulkAlert(elem){
    Swal.fire({
      title: 'Are you sure?',
      text: "This will auto accept the changes!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        this.batchService.bulkUpdate(elem).subscribe(data => {
          if(this.sortEvent){
            const params: PaginationParamSort = new PaginationParamSort(1, this.paginator.pageSize, this.sortEvent.active, this.sortEvent.direction);
            this.fetchTableRecordsSort( params);
          }else{
            let params: PaginationParam = new PaginationParam(this.pageNumber, this.paginator.pageSize);
            this.fetchTableRecords(params);
          }
        })
      }
    })
  }
}
