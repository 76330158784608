import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { BatchMatchService } from '../batch-match.service';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.css']
})
export class InformationComponent implements OnInit {
  batchId: string;
  dataSource = new MatTableDataSource<any[]>();
  // displayedColumns: string[] = ['username','c','email','created_at','startDate','update_at']
  displayedColumns: string[] = ['username']
  infoData: any;
  constructor(
    public batchService: BatchMatchService
  ) { }

  ngOnInit() {
    this.batchId = sessionStorage.getItem('batchId');
    this.getInfo();
  }

  getInfo() {
    this.batchService.getInformation(this.batchId).subscribe((data: any[]) => {
      this.dataSource = new MatTableDataSource(data);
      this.infoData = data;
    })
  }

}
