import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
//import { TestServiceService } from './test-service.service';
import { MatPaginator } from '@angular/material/paginator';
import { NavigationExtras, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { BatchDetailService } from './batch-details.service';
import {behaviour} from 'src/environments/behaviour'
import { MatDialog } from '@angular/material/dialog';
import { SearchMatchComponent } from './search-match/search-match.component';
export class PaginationParam {
  page: number;
  size: number; //number of rows per page
  reconciled : number;

  constructor(pageNbr: number, noOfItems: number, reconcile) {
      this.page = pageNbr;
      this.size = noOfItems;
      this.reconciled  = reconcile;
  }
}
export class PaginationParamSort {
  page: number;
  size: number; //number of rows per page
  sort: string = '';
  dir: string = '';
  reconciled : number;

  constructor(pageNbr: number, noOfItems: number, sortString: string, dirString: string, reconcile: number) {
      this.page = pageNbr;
      this.size = noOfItems;
      this.sort = sortString;
      this.dir = dirString;
      this.reconciled = reconcile;
  }
}
@Component({
  selector: 'app-batch-details',
  templateUrl: './batch-details.component.html',
  styleUrls: ['./batch-details.component.css']
})
export class BatchDetailsComponent implements OnInit {
  pageSizeOptions: number[] = [];
  sortEvent: any = null;
  totalElem: number = 5;
  displayedColumns: string[] = ['first_name', 'last_name', 'email', 'imported','recon', 'saved'];
  //dataSource = ELEMENT_DATA;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource = new MatTableDataSource<any[]>();
  rowsPerPage: number = 40;
  source = new FormControl();
  pageNumber: number = 1;
  batchId : any ='';
  batchInfo : any;
  reconciled : number = 0;
  reconDefinitions= {
        "0": ["Pending Recon"],
        "2": ["Successful","View"],
        "3": ["Multiple Matches","Recon"],
        "4": ["No Match", "Recon"],
        "5": ["Pending Verification","Recon"]
};
  constructor( private batchService: BatchDetailService,
    private router: Router, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.batchInfo = JSON.parse(localStorage.getItem('selectedBatch'));
    this.batchId = this.batchInfo.id;
    let params: PaginationParam = new PaginationParam(1, this.rowsPerPage, this.reconciled);
    this.fetchTableRecords(params);
  }
  fetchRecord(eve){
      this.reconciled = eve.value;
      let params: PaginationParam = new PaginationParam(1, this.rowsPerPage, this.reconciled);
    this.fetchTableRecords(params);
  }
  fetchTableRecords(params) {
    //let params: PaginationParam = new PaginationParam(this.pageNumber, this.paginator.pageSize);
    
    this.batchService.getTableRecords(behaviour.sourcesConfig[this.batchInfo.source],this.batchId,this.batchId,params).subscribe(data => {
      this.dataSource = new MatTableDataSource(data.records);
      this.totalElem = data.totalCount;
      this.pageSizeOptionsCalculation(data.totalCount);
    })
  }
  getReconDefinition(reconciled,button){
    if (button) {
      return this.reconDefinitions[reconciled] ? this.reconDefinitions[reconciled][1] : ' ';
  } else {
      return this.reconDefinitions[reconciled] ? this.reconDefinitions[reconciled][0] : ' ';
  }
  
  }
  updateRecords(elem){
    const navigationExtras: NavigationExtras = {
      queryParams: {
        id: elem.id,
        source: elem.details.source_name,
        recon: elem.reconciled
      }
  };
  this.router.navigate(['/manual-recon'], navigationExtras);
  }
  getReconDefinitionBtn(reconciled) {
    if( reconciled === 3){
      return 'Recon';
    } else if(reconciled === 5) {
      return 'Recon';
    } else if(reconciled === 2) {
      return 'View';
    } else if(reconciled === 4){
      return '  ';
    }
    
  }
  fetchTableRecordsSort(params) {
    //let params: PaginationParam = new PaginationParam(this.pageNumber, this.paginator.pageSize);
    
    this.batchService.getTableRecordsSort(behaviour.sourcesConfig[this.batchInfo.source],this.batchId,this.batchId,params).subscribe(data => {
      this.dataSource = new MatTableDataSource(data.records);
      this.totalElem = data.totalCount;
      this.pageSizeOptionsCalculation(data.totalCount);
    })
  }
  navSales(element){
    let url = 'https://umuc.lightning.force.com/lightning/r/Contact/'+element.matched_record_id+'/view'
    window.open(url, "_blank");
  }
  openDetail(ele){
    
  }
  getName(elem){
    if(elem.first_name){
      return elem.first_name;
    }else if(elem.company_name){
      let test1 = `<span> ${elem.company_name} <img class="imgSize" src="./assets/images/business.png" style="width: 35px;"></img></span>`
      return test1;
    }else{
      return ;
    }
  }
  onSortData(event: any) {
    this.sortEvent = event;
    setTimeout(() => {
        const params: PaginationParamSort = new PaginationParamSort(1, this.paginator.pageSize, event.active, event.direction, this.reconciled);
        this.fetchTableRecordsSort( params);
    });
}
  onPagination(event: any) {
    this.pageNumber = event.pageIndex + 1;
    let params: PaginationParam = new PaginationParam(this.pageNumber, this.paginator.pageSize, this.reconciled);
    this.fetchTableRecords(params);
  }
  pageSizeOptionsCalculation(total: number) {
    const defaultPageArray: number[] = [5, 10, 25, 50, 100, 250, 500, total];
    this.pageSizeOptions = [];
    this.pageSizeOptions = defaultPageArray.filter(x => x <= total);
  }

  OpenSearchPage() {
    const dialogRef = this.dialog.open(SearchMatchComponent, {
      width: '1750px',
      height: "500px"
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  goBack(){
    this.router.navigate(['/batch-match']);
  }
}
