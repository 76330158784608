import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ScheduleUploadComponent } from "./schedule-upload.component";

export class ScheduleUpload {
    created_at: string;
    display_name: string;
    frequency: any;
    id: number;
    last_run: string;
    mail_to: string;
    model: string;
    name: string;
    restricted: boolean;
    schedulable: boolean;
    source_type: string;
    updated_at: string;

    static asFormGroup(scheduleUpload: ScheduleUpload): FormGroup {
        const fg = new FormGroup({
            created_at: new FormControl(scheduleUpload.created_at),
            display_name: new FormControl(scheduleUpload.display_name),
            frequency: new FormControl(scheduleUpload.frequency,Validators.required),
            id: new FormControl(scheduleUpload.id),
            last_run: new FormControl(scheduleUpload.last_run),
            mail_to: new FormControl(scheduleUpload.mail_to),
            model: new FormControl(scheduleUpload.model),
            name: new FormControl(scheduleUpload.name),
            restricted: new FormControl(scheduleUpload.restricted),
            schedulable: new FormControl(scheduleUpload.schedulable),
            source_type: new FormControl(scheduleUpload.source_type),
            updated_at: new FormControl(scheduleUpload.updated_at)
          });
          return fg;
    }
}

export class IdrSource {
    idr_source:  IdrData = new IdrData();
  }

  export class IdrData {
    mail_to: string;
    frequency: number;
  }