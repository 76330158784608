import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SearchPersonDialogComponent } from 'src/app/search-person-dialog/search-person-dialog.component';
import { PaginationParam, PersonSearch, SearchParam } from 'src/app/search-person/search-person';
import { SearchPersonService } from 'src/app/search-person/search-person.service';

@Component({
  selector: 'app-search-match',
  templateUrl: './search-match.component.html',
  styleUrls: ['./search-match.component.css']
})
export class SearchMatchComponent implements OnInit {
  resultsLength = 0;
  pageSizeOptions: number[] = [];
  rowsPerPage: number = 20;
  totalElem : number = 5;
  pageNumber: number = 0;
  displayedColumns: string[] = ['firstname', 'lastname', 'email', 'salesforceid','emplid', 'advanceid', 'imodulusid', 'questid'];
  dataSource = new MatTableDataSource<PersonSearch[]>();
  popupData: any;
  searchParams: SearchParam = new SearchParam();
  searchForm: FormGroup
  constructor(
    public dialog: MatDialog,
    public searchPersonService: SearchPersonService,
    public fb: FormBuilder
  ) { }

  ngOnInit(): void {

    this.searchForm = this.fb.group({
      firstName: new FormControl(null),
      lastName: new FormControl(null),
      salesforceId: new FormControl(null),
      emplId: new FormControl(null),
      advanceId: new FormControl(null),
      email: new FormControl(null),
      imodulesId: new FormControl(null),
      careerQuestId: new FormControl(null),
    })
    let params: PaginationParam = new PaginationParam(1, this.rowsPerPage);
    this.getPeople(params);

  }
 
getPeople(params) {
    this.searchPersonService.getPersonData(params).subscribe(data => {
    this.dataSource = new MatTableDataSource(data.ia_people);
    this.totalElem = data.totalCount;
  })
}

getPeopleSearch(){
  let pagination: PaginationParam = new PaginationParam(1, this.rowsPerPage);
  this.searchPersonService.getSearchPerson(this.searchParams, pagination).subscribe(data => {
  this.dataSource = new MatTableDataSource(data.ia_people);
  this.totalElem = data.totalCount;
})
}
navSales(element){
  let url = 'https://umuc.lightning.force.com/lightning/r/Contact/'+element.salesforce_id+'/view'
  window.open(url, "_blank");
}

}
